import mergeDefault from 'lodash.merge';
import {
  clone as cloneDefault,
  get as defaultGet,
  omit as defaultOmit,
  pick as defaultPick,
  set as defaultSet,
  mapEntries as mapEntriesDefault,
  mapKeys as mapKeysDefault,
  mapValues as mapValuesDefault
} from 'radash';

export namespace objectHelpers {
  export const mapEntries = mapEntriesDefault;
  export const mapValues = mapValuesDefault;
  export const mapKeys = mapKeysDefault;
  export const get = defaultGet;
  export const set = defaultSet;
  export const omit = defaultOmit;
  export const pick = defaultPick;
  export function merge<T = any>(target: T, ...sources: (T | undefined)[]): T {
    return mergeDefault(target, ...sources);
  }
  export const clone = cloneDefault;
  export function jsonClone<T extends object>(value: T) {
    return JSON.parse(JSON.stringify(value)) as T;
  }

  export function getKeyByValue<T extends object>(obj: T, value: T[keyof T]): keyof T {
    const indexOf = Object.values(obj).indexOf(value);
    const key = Object.keys(obj)[indexOf];
    return key as keyof T;
  }

  export function removeEmpty<T extends object>(obj: T, includeMoreValues = false): T {
    return Object.fromEntries(
      Object.entries(obj).filter(([, v]) => {
        if (includeMoreValues) {
          if (v === 0) {
            return true;
          }

          if (v === false) {
            return true;
          }
        }

        return Boolean(v);
      })
    ) as T;
  }
}
