"use client"

import browserImageCompression from "browser-image-compression"
import heic2any from "heic2any"

export async function imageCompression(file: File) {
  // const maxSize = (Number(process.env.NEXT_PUBLIC_MAX_FILE_SIZE) || 100) * 1024

  const options = {
    useWebWorker: false,
    maxWidthOrHeight: 1000,
    maxSizeMB: 1,
    fileType: "image/jpeg", // Change to "image/jpeg" for consistency
  }

  // Check if the file is in HEIC format
  if (file.type === "image/heic") {
    // Convert HEIC to JPEG using heic2any
    const arrayBuffer = await file.arrayBuffer()
    const jpegArrayBuffers = await heic2any({
      blob: new Blob([arrayBuffer], { type: "image/heic" }),
      toType: "image/jpeg",
    })

    // Check if it's a single or multi-image HEIC
    const jpegBlob = Array.isArray(jpegArrayBuffers)
      ? new Blob(jpegArrayBuffers, { type: "image/jpeg" })
      : jpegArrayBuffers

    // Update file to the converted JPEG
    file = new File([jpegBlob], file.name.replace(/\.heic$/, ".jpg"), {
      type: "image/jpeg",
    })
    const compressedFile = await browserImageCompression(file, options)
    return compressedFile
  } else {
    const compressedFile = await browserImageCompression(file, options)
    return compressedFile
  }
}
