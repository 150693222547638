import type { AxiosError, AxiosRequestConfig } from "axios";

import axios from "axios";

import { AxiosClient } from "./client";

const apiClient = new AxiosClient({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
});

export function mutator<T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> {
  const source = axios.CancelToken.source();

  const combinedConfig = {
    ...config,
    ...options,
    cancelToken: source.token,
  };

  const promise: any = apiClient.adaptor(combinedConfig).then((response) => {
    return response.data;
  });

  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
}

export type ErrorType<Error> = AxiosError<Error>;
