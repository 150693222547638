"use client"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ReactNode, useCallback, useMemo, useState } from "react"
import { StartContext, StartContextValue } from "./context"

import { AxiosProgressEvent } from "axios"
import { enqueueSnackbar } from "notistack"
import { imageCompression } from "./utils"
import { useFindSimilarItemsAPI } from "@/services/swagger/auth/axios/luxi"
import {
  DetectedObjects,
  ResponseSimilarItems,
} from "@/services/swagger/auth/models"
import { useSetAtom } from "jotai"
import { activeItems } from "./RecognizePhoto/partials/ImageConnector"

import { usePathname, useRouter } from "next/navigation"

export type StartProviderProps = {
  children?: ReactNode
}

export function StartProvider(props: StartProviderProps) {
  const { children } = props
  const router = useRouter()
  const pathName = usePathname()
  const isInHome = pathName.includes("/")

  const queryClient = useQueryClient()
  const [progress, setProgress] = useState(0)
  const [needAuth, setNeedAuth] = useState(false)
  const [isSuccessUpload, seIsSuccessUpload] = useState(false)
  const setActiveItem = useSetAtom(activeItems)

  const {
    mutate: findSimilarItems,
    data: findSimilarItemsData,
    reset: resetFindSimilarItems,
    isPending: isFindingSimilarItems,
  } = useFindSimilarItemsAPI({
    request: {
      onUploadProgress: (event: AxiosProgressEvent) => {
        const { total = 0, loaded = 0 } = event
        const percent = Math.round((loaded * 100) / total)
        setProgress(percent)
      },
    },
    mutation: {
      onMutate: () => {
        setProgress(0)
      },
      onSuccess: (data) => {
        setSimilarItemsUpdate({ ...data, isHistory: true })
        seIsSuccessUpload(true)
        queryClient.invalidateQueries({ queryKey: ["GET_HISTORY_LIST"] })
        queryClient.invalidateQueries({ queryKey: ["GET_USER"] })
        if (isInHome) router.push("/zoom")
      },
      onError: (err: any) => {
        seIsSuccessUpload(false)
        if (err?.response?.status === 403) {
          setNeedAuth(true)
        }
        enqueueSnackbar({
          message: "Upload was not successful!",
          variant: "error",
        })
      },
    },
  })

  const [similarItemsUpdate, setSimilarItemsUpdate] = useState<
    ResponseSimilarItems | undefined
  >(
    findSimilarItemsData
      ? {
          ...findSimilarItemsData,
          isHistory: true,
        }
      : undefined
  )

  const {
    mutate: upload,
    data: uploadData,
    reset: resetUpload,
    isPending: isUploadingFile,
  } = useMutation<File, Error, File>({
    mutationFn: async (file) => {
      return file
    },
    onMutate: () => {
      setProgress(0)
    },
    onSuccess: (data) => {
      setActiveItem(null)
      setSimilarItemsUpdate(undefined)
      findSimilarItems({ data: { input_file: data } })
    },
    onError: () => {
      setActiveItem(null)
      setSimilarItemsUpdate(undefined)
      enqueueSnackbar({
        message: "Upload was not successful!",
        variant: "error",
      })
    },
  })

  const [deleteImage, setDeleteImage] = useState(false)

  const image = useMemo(() => {
    if (similarItemsUpdate?.input_image_url)
      return similarItemsUpdate.input_image_url
    if (deleteImage) return
    if (!uploadData) return
    return URL.createObjectURL(uploadData)
  }, [deleteImage, similarItemsUpdate?.input_image_url, uploadData])

  const onUpload = useCallback(
    async (file?: File) => {
      if (!file) return
      const compressImage = (await imageCompression(file)) as File
      setDeleteImage(false)
      upload(compressImage)
    },

    [upload]
  )

  const onDeleteImage = useCallback(() => {
    setDeleteImage(true)
    seIsSuccessUpload(false)
    setSimilarItemsUpdate(undefined)
  }, [])

  const onCancel = useCallback(() => {
    resetUpload()
    setProgress(0)
    resetFindSimilarItems()
    seIsSuccessUpload(false)
  }, [resetFindSimilarItems, resetUpload])

  const value: StartContextValue = useMemo(
    () => ({
      image,
      onCancel,
      onUpload,
      onDeleteImage,
      progress,
      needAuth,
      setNeedAuth,
      isUploadingFile,
      file: uploadData,
      isFindingSimilarItems,
      isSuccessUpload,
      similarItems: similarItemsUpdate,
      setSimilarItems: setSimilarItemsUpdate,
    }),
    [
      image,
      onCancel,
      onUpload,
      onDeleteImage,
      progress,
      isUploadingFile,
      uploadData,
      isFindingSimilarItems,
      isSuccessUpload,
      similarItemsUpdate,
      needAuth,
    ]
  )

  return <StartContext.Provider value={value}>{children}</StartContext.Provider>
}
