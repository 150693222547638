import axios, { AxiosInstance, CreateAxiosDefaults } from "axios"
import { requestLanguage } from "./interceptors"
import { objectHelpers } from "@/lib/objectHelpers"
import { requestAuth } from "./interceptors/auth/requestAuth"

export class AxiosClient {
  private readonly adapter: AxiosInstance

  constructor(config?: CreateAxiosDefaults) {
    const defaultConfig = {
      timeout: 120 * 1000, // 2min
    }

    // initial adapter
    this.adapter = axios.create(
      objectHelpers.merge<CreateAxiosDefaults>(defaultConfig, config)
    )

    this.adapter.interceptors.request.use(requestLanguage)
    this.adapter.interceptors.request.use(requestAuth)
  }

  public get adaptor(): AxiosInstance {
    return this.adapter
  }
}
