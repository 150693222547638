"use client"

import { queryClient } from "@/services/configs/query/config"
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { GoogleOAuthProvider } from "@react-oauth/google"

import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react"
import { TooltipProvider } from "@radix-ui/react-tooltip"
import { SnackbarProvider } from "notistack"
import { NextUIProvider } from "@nextui-org/react"
import { StartProvider } from "@/modules/start/provider"
import { initializeGoogleTagManager } from "@/lib/googleTagManager"

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [client] = useState(queryClient)

  useEffect(() => {
    // Initialize Google Tag Manager with your GTM ID
    initializeGoogleTagManager("GTM-MBLDWH68")
  }, [])

  return (
    <QueryClientProvider client={client}>
      <NextUIProvider className="h-full w-full">
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_CLIENT_ID ?? ""}>
          <ReactQueryStreamedHydration>
            <TooltipProvider>
              <SnackbarProvider>
                <StartProvider>{children}</StartProvider>
              </SnackbarProvider>
            </TooltipProvider>
          </ReactQueryStreamedHydration>
          <ReactQueryDevtools initialIsOpen={false} />
        </GoogleOAuthProvider>
      </NextUIProvider>
    </QueryClientProvider>
  )
}
export default Providers
