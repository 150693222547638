import type { InternalAxiosRequestConfig } from "axios";
import { getCookie } from "typescript-cookie";

export function requestAuth(request: InternalAxiosRequestConfig) {
  const credentialCookie: string | undefined = getCookie("userCredential");

  if (credentialCookie) {
    const userData = JSON.parse(credentialCookie);
    request.headers["Authorization"] = `Bearer ${userData?.access_token}`;
  }

  return request;
  // }
}
