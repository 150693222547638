/* eslint-disable jsx-a11y/alt-text */
"use client"
/* eslint-disable @next/next/no-img-element */

import { useQuery } from "@tanstack/react-query"
import { nanoid } from "nanoid"
import { useEffect, useMemo, useState } from "react"
import useResizeObserver from "use-resize-observer"
import { useStartContext } from "../../../context"
import { LOADING_MOCK } from "./constants"
import { motion, useAnimation } from "framer-motion"
import { Loader, Minus, Plus } from "lucide-react"
import { DetectedObjects } from "@/services/swagger/auth/models"
import clsx from "clsx"

type ImageConnectorProps = {
  setActiveItem: (item: DetectedObjects) => void
  activeItem?: DetectedObjects
  isLoading?: boolean
}

const BlinkingElement = ({
  item,
  activeItem,
  setActiveItem,
  isLoading,
}: any) => {
  const controls = useAnimation()

  const flashZoomVariants = {
    initial: { scale: 1 },
    animate: { scale: [1, 0.9, 1], transition: { duration: 0.5 } },
  }

  const handleClick = () => {
    if (isLoading) return
    if (
      activeItem &&
      activeItem?.object_location?.x === item.object_location?.x
    )
      return setActiveItem(undefined)
    controls.start("animate")
    setActiveItem?.(item)
    const itemsListElement = document.getElementById("items-list")
    if (itemsListElement) {
      const rect = itemsListElement.getBoundingClientRect()
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const offsetPosition = rect.top + scrollTop - 200

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      const shouldZoomIn = Math.random() < 0.2
      controls.start({
        // scale: shouldZoomIn ? 1 : 0.9,
        opacity: !shouldZoomIn ? 1 : 0.5,
      })
    }, 500)

    return () => {
      clearInterval(blinkInterval)
    }
  }, [controls])
  const isActive = activeItem?.object_location?.x === item.object_location?.x
  return (
    <motion.span
      key={nanoid()}
      onClick={handleClick}
      id="button-select-item"
      className={clsx(
        `flex z-20 cursor-pointer text-center items-center justify-center w-5 h-5  border-1 rounded-full border-primary-08 bg-white absolute `,
        isActive && "!bg-primary-08 border-white z-21"
      )}
      style={{
        top: item.object_location?.y,
        left: item.object_location?.x,
      }}
      initial="initial"
      animate={isActive ? undefined : controls}
      variants={isActive ? undefined : flashZoomVariants}
    >
      {isLoading ? (
        <Loader color="#1C3D86" />
      ) : activeItem?.object_location?.x === item.object_location?.x ? (
        <Minus color="#fff" />
      ) : (
        <Plus color="#1C3D86" />
      )}
    </motion.span>
  )
}

export const ImageConnector: React.FC<ImageConnectorProps> = ({
  setActiveItem,
  activeItem,
  isLoading,
}) => {
  const { image, similarItems } = useStartContext()
  const detectedObjects = similarItems?.detected_objects?.at(0)

  const { ref, height = 0, width = 0 } = useResizeObserver()

  const { data: { naturalHeight = 0, naturalWidth = 0 } = {} } = useQuery({
    queryKey: ["image", image],
    queryFn: async () => {
      const { naturalHeight = 0, naturalWidth = 0 } =
        await new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image()
          img.onload = () => resolve(img)
          img.onerror = (err) => reject(err)
          img.src = image!
        })

      return { naturalHeight, naturalWidth }
    },
  })

  const calculated = useMemo(() => {
    const scaleX = naturalWidth / width

    const scaleY = naturalHeight / height
    return detectedObjects?.map((item) => {
      return {
        ...item,
        id: nanoid(),
        object_location: {
          x: (item.object_location?.x ?? 0) / scaleX,
          y: (item.object_location?.y ?? 0) / scaleY,
        },
      }
    })
  }, [detectedObjects, height, naturalHeight, naturalWidth, width])

  const isLoaded = useMemo(
    () => !detectedObjects?.some((item) => !!item.similar_items?.length),
    [detectedObjects]
  )

  return (
    <div className="flex justify-center  w-full items-stretch h-full relative z-[1]">
      <div ref={ref} className="relative">
        <img
          src={image}
          className=" top-0 max-h-[500px] max-w-[316px] rounded-md"
        />
        {calculated &&
          calculated.map((item) => {
            return (
              <img
                key={item.object_location?.x}
                src={item.object_image_url}
                className={`${
                  activeItem?.object_location?.x === item.object_location?.x
                    ? "z-10 absolute top-0 rounded-md"
                    : "hidden"
                } max-h-[500px] max-w-[316px]`}
              />
            )
          })}
        {isLoaded && calculated && (
          <div className="absolute animate-pulse bg-primary-06 bg-opacity-70 inset-0 rounded-lg" />
        )}

        {calculated &&
          calculated?.map((item) => (
            <BlinkingElement
              key={nanoid()}
              item={item}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          ))}
      </div>
    </div>
  )
}
