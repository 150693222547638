/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * warehouse
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query"
import type {
  MutationFunction,
  UseMutationOptions,
} from "@tanstack/react-query"
import type {
  BodyFindSimilarItemsApiFindSimilarItemsPost,
  HTTPValidationError,
  ResponseSimilarItems,
} from "../models"
import { ErrorType, mutator } from "@/services/configs/axios"

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * @summary Find Similar Items
 */
export const findSimilarItemsAPI = (
  bodyFindSimilarItemsApiFindSimilarItemsPost: BodyFindSimilarItemsApiFindSimilarItemsPost,
  options?: SecondParameter<typeof mutator>
) => {
  const formData = new FormData()
  if (bodyFindSimilarItemsApiFindSimilarItemsPost.input_file)
    formData.append(
      "input_file",
      bodyFindSimilarItemsApiFindSimilarItemsPost.input_file
    )
  return mutator<ResponseSimilarItems>(
    {
      url: bodyFindSimilarItemsApiFindSimilarItemsPost.request_token
        ? `/api/find-similar-items?request_token=${bodyFindSimilarItemsApiFindSimilarItemsPost.request_token}` +
          `${bodyFindSimilarItemsApiFindSimilarItemsPost.price_max ? `&price_max=${bodyFindSimilarItemsApiFindSimilarItemsPost.price_max}` : ""}` +
          `${bodyFindSimilarItemsApiFindSimilarItemsPost.price_min ? `&price_min=${bodyFindSimilarItemsApiFindSimilarItemsPost.price_min}` : ""}` +
          `${bodyFindSimilarItemsApiFindSimilarItemsPost.gender ? `&gender=${bodyFindSimilarItemsApiFindSimilarItemsPost.gender}` : ""}` +
          `${bodyFindSimilarItemsApiFindSimilarItemsPost.color_key ? `&color_key=${bodyFindSimilarItemsApiFindSimilarItemsPost.color_key}` : ""}`
        : `/api/find-similar-items`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFindSimilarItemsApiFindSimilarItemsPost.request_token
        ? undefined
        : formData,
    },
    options
  )
}

export const getFindSimilarItemsAPIMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof findSimilarItemsAPI>>,
    TError,
    { data: BodyFindSimilarItemsApiFindSimilarItemsPost },
    TContext
  >
  request?: SecondParameter<typeof mutator>
}): UseMutationOptions<
  Awaited<ReturnType<typeof findSimilarItemsAPI>>,
  TError,
  { data: BodyFindSimilarItemsApiFindSimilarItemsPost },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof findSimilarItemsAPI>>,
    { data: BodyFindSimilarItemsApiFindSimilarItemsPost }
  > = (props) => {
    const { data } = props ?? {}

    return findSimilarItemsAPI(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FindSimilarItemsAPIMutationResult = NonNullable<
  Awaited<ReturnType<typeof findSimilarItemsAPI>>
>
export type FindSimilarItemsAPIMutationBody =
  BodyFindSimilarItemsApiFindSimilarItemsPost
export type FindSimilarItemsAPIMutationError = ErrorType<HTTPValidationError>

/**
 * @summary Find Similar Items
 */
export const useFindSimilarItemsAPI = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof findSimilarItemsAPI>>,
    TError,
    { data: BodyFindSimilarItemsApiFindSimilarItemsPost },
    TContext
  >
  request?: SecondParameter<typeof mutator>
}) => {
  const mutationOptions = getFindSimilarItemsAPIMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Find Similar Sample
 */
export const findSimilarSampleAPI = (
  bodyFindSimilarSampleApiFindSimilarSamplePost: BodyFindSimilarItemsApiFindSimilarItemsPost,
  options?: SecondParameter<typeof mutator>
) => {
  return mutator<ResponseSimilarItems>(
    {
      url: bodyFindSimilarSampleApiFindSimilarSamplePost.request_token
        ? `/api/find-similar-sample?request_token=${bodyFindSimilarSampleApiFindSimilarSamplePost.request_token}` +
          `${bodyFindSimilarSampleApiFindSimilarSamplePost.price_max ? `&price_max=${bodyFindSimilarSampleApiFindSimilarSamplePost.price_max}` : ""}` +
          `${bodyFindSimilarSampleApiFindSimilarSamplePost.price_min ? `&price_min=${bodyFindSimilarSampleApiFindSimilarSamplePost.price_min}` : ""}` +
          `${bodyFindSimilarSampleApiFindSimilarSamplePost.gender ? `&gender=${bodyFindSimilarSampleApiFindSimilarSamplePost.gender}` : ""}` +
          `${bodyFindSimilarSampleApiFindSimilarSamplePost.color_key ? `&color_key=${bodyFindSimilarSampleApiFindSimilarSamplePost.color_key}` : ""}`
        : `/api/find-similar-Sample`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    },
    options
  )
}

export const getFindSimilarSampleAPIMutationOptions = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof findSimilarSampleAPI>>,
    TError,
    { data: BodyFindSimilarItemsApiFindSimilarItemsPost },
    TContext
  >
  request?: SecondParameter<typeof mutator>
}): UseMutationOptions<
  Awaited<ReturnType<typeof findSimilarSampleAPI>>,
  TError,
  { data: BodyFindSimilarItemsApiFindSimilarItemsPost },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof findSimilarSampleAPI>>,
    { data: BodyFindSimilarItemsApiFindSimilarItemsPost }
  > = (props) => {
    const { data } = props ?? {}

    return findSimilarSampleAPI(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type FindSimilarSampleAPIMutationResult = NonNullable<
  Awaited<ReturnType<typeof findSimilarSampleAPI>>
>
export type FindSimilarSampleAPIMutationBody =
  BodyFindSimilarItemsApiFindSimilarItemsPost
export type FindSimilarSampleAPIMutationError = ErrorType<HTTPValidationError>

/**
 * @summary Find Similar Sample
 */
export const useFindSimilarSampleAPI = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof findSimilarSampleAPI>>,
    TError,
    { data: BodyFindSimilarItemsApiFindSimilarItemsPost },
    TContext
  >
  request?: SecondParameter<typeof mutator>
}) => {
  const mutationOptions = getFindSimilarSampleAPIMutationOptions(options)

  return useMutation(mutationOptions)
}
